import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PickupItem = _resolveComponent("PickupItem")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({}),
    "data-test": "pickup-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DmAsyncContent, {
        loading: !_ctx.isLoaded,
        error: _ctx.isLoadingError,
        reloadable: "",
        class: _ctx.$bem({e: 'items-wrapper'}),
        onReload: _ctx.reload
      }, {
        default: _withCtx(() => [
          (_ctx.items.length > 0)
            ? (_openBlock(), _createBlock("ul", {
                key: 0,
                class: _ctx.$bem({e: 'items'})
              }, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                  return (_openBlock(), _createBlock(_component_PickupItem, {
                    key: item.id,
                    item: item,
                    class: _ctx.$bem({e: 'item'}),
                    onPickup: _ctx.reload
                  }, null, 8, ["item", "class", "onPickup"]))
                }), 128))
              ], 2))
            : (_openBlock(), _createBlock("div", {
                key: 1,
                class: _ctx.$bem({e: 'no-items-text'})
              }, _toDisplayString(_ctx.$t('pickup.noItems')), 3))
        ]),
        _: 1
      }, 8, ["loading", "error", "class", "onReload"])
    ]),
    _: 1
  }, 8, ["class"]))
}