import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppSpelledWord = _resolveComponent("AppSpelledWord")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModal = _resolveComponent("AppModal")

  return (_openBlock(), _createBlock(_component_AppModal, {
    "model-value": _ctx.modelValue,
    class: _ctx.$bem({}),
    loading: _ctx.loading,
    "data-test": "pickup-item-confirmation",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppModalHeader, {
        title: _ctx.$t('common.attention'),
        "with-close-button": "",
        onClose: _ctx.close
      }, null, 8, ["title", "onClose"]),
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({e: 'info'})
          }, _toDisplayString(_ctx.$t('pickup.confirmationDescription')), 3),
          _createVNode("div", {
            class: _ctx.$bem({e: 'car-details'})
          }, [
            _createVNode("strong", {
              class: _ctx.$bem({e: 'car-name'})
            }, _toDisplayString(_ctx.car), 3),
            _createVNode("div", null, [
              _createVNode(_component_AppSpelledWord, { text: _ctx.licensePlateNumber }, null, 8, ["text"])
            ])
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          _createVNode(_component_AppModalButton, { onClick: _ctx.close }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_AppModalButton, {
            color: "primary",
            dark: "",
            class: _ctx.$bem({e: 'confirmation-button'}),
            onClick: _ctx.pickup
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.confirm')), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value", "class", "loading", "onClose"]))
}