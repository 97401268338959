
import { defineComponent, PropType, inject } from 'vue';
import {
  DmButton
} from '@dobrymechanik/vue-ui';
import {
  AppSpelledWord
} from '@/components';
import { ReadyForPickupItem } from '@/api/models';
import PickupItemConfirmation from './Confirmation';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { formatPhone } from '@/helpers/phone';

export default defineComponent({
  name: 'PickupItem',
  components: {
    DmButton,
    PickupItemConfirmation,
    AppSpelledWord
  },
  props: {
    item: {
      type: Object as PropType<ReadyForPickupItem>,
      required: true
    }
  },
  emits: ['pickup'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      isConfirmationOpen: false as boolean
    };
  },
  computed: {
    formattedPhone (): string {
      return formatPhone(this.item.phone);
    }
  },
  methods: {
    openConfirmation (): void {
      this.isConfirmationOpen = true;
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.READY_FOR_PICKUP,
        action: 'Ready for pickup menu item clicked'
      });
    },
    pickup (): void {
      this.$emit('pickup');
    }
  }
});
