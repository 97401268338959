
import { defineComponent } from 'vue';
import {
  URI_READY_FOR_PICKUP,
  URI_READY_FOR_PICKUP_COUNTER
} from '@/api/endpoints';
import { ReadyForPickupItem } from '@/api/models';
import {
  PickupItem
} from './_components';
import { AppWrapper } from '@/components';
import {
  DmAsyncContent
} from '@dobrymechanik/vue-ui';
import { mapMutations } from 'vuex';
import { SET_READY_FOR_PICKUP_AMOUNT } from '@/store/mutations';
import { ReadyForPickupCount } from '@/api/models/pickup';

export default defineComponent({
  name: 'Pickup',
  components: {
    PickupItem,
    AppWrapper,
    DmAsyncContent
  },
  data () {
    return {
      isLoaded: false,
      items: [] as ReadyForPickupItem[],
      isLoadingError: false as boolean
    };
  },
  created () {
    this.getItems();
  },
  methods: {
    ...mapMutations({
      setReadyForPickupAmount: SET_READY_FOR_PICKUP_AMOUNT
    }),
    async getItems (): Promise<void> {
      this.isLoaded = false;
      try {
        this.items = (await this.$http.get<ReadyForPickupItem[]>(URI_READY_FOR_PICKUP)).data;
      } catch (e) {
        this.isLoadingError = true;
      }
      this.isLoaded = true;
    },
    async fetchReadyForPickupAmount (): Promise<void> {
      const counter = (await this.$http.get<ReadyForPickupCount>(URI_READY_FOR_PICKUP_COUNTER)).data.count;
      this.setReadyForPickupAmount(counter);
    },
    reload (): void {
      this.isLoadingError = false;
      this.getItems();
      this.fetchReadyForPickupAmount();
    }
  }
});
