
import { defineComponent, PropType, inject } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton,
  AppSpelledWord
} from '@/components';
import { URI_PICKUP } from '@/api/endpoints';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';

export default defineComponent({
  name: 'PickupItemConfirmation',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    AppSpelledWord
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    car: {
      type: String as PropType<string>,
      required: true
    },
    licensePlateNumber: {
      type: String as PropType<string>,
      required: true
    },
    repairId: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: ['update:modelValue', 'pickup'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      loading: false as boolean
    };
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close (): void {
      this.$emit('update:modelValue', false);
    },
    async pickup (): Promise<void> {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.READY_FOR_PICKUP,
        action: 'Car collected confirmation button clicked'
      });
      try {
        const data = new FormData();
        this.loading = true;
        data.append('repairId', this.repairId);
        try {
          const id = (await this.$http.post<{id: string}>(URI_PICKUP, data)).data.id;
          this.$emit('pickup', id);
          this.trackEvent({
            event: TrackedEventType.SUCCESS,
            category: TrackedEventCategory.READY_FOR_PICKUP,
            action: 'Car collected'
          });
          this.close();
        } catch (e) {
          this.createNotification({
            message: this.$t('pickup.failure'),
            type: 'error'
          });
          this.trackEvent({
            event: TrackedEventType.ERROR,
            category: TrackedEventCategory.READY_FOR_PICKUP,
            action: 'Car collection failed'
          });
        }
        this.loading = false;
      } catch (e) {

      }
    }
  }
});
