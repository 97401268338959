import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSpelledWord = _resolveComponent("AppSpelledWord")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_PickupItemConfirmation = _resolveComponent("PickupItemConfirmation")

  return (_openBlock(), _createBlock("li", {
    class: [_ctx.$bem({}), "is-hoverable"],
    "data-test": "pickup-item"
  }, [
    _createVNode("div", {
      class: _ctx.$bem({e: 'car'})
    }, [
      _createVNode("div", {
        class: _ctx.$bem({e: 'car-name'})
      }, _toDisplayString(_ctx.item.car), 3),
      _createVNode(_component_AppSpelledWord, {
        text: _ctx.item.licensePlateNumber
      }, null, 8, ["text"])
    ], 2),
    _createVNode("div", {
      class: _ctx.$bem({e: 'user'})
    }, [
      _createVNode("div", {
        class: _ctx.$bem({e: 'user-name'})
      }, _toDisplayString(_ctx.item.name), 3),
      _createVNode("div", null, _toDisplayString(_ctx.formattedPhone), 1),
      _createVNode("div", null, _toDisplayString(_ctx.item.email), 1)
    ], 2),
    _createVNode("div", {
      class: _ctx.$bem({e: 'reason'}),
      innerHTML: _ctx.$filters.addLineBreaks(_ctx.item.reason)
    }, null, 10, ["innerHTML"]),
    _createVNode("div", {
      class: _ctx.$bem({e: 'actions'})
    }, [
      _createVNode(_component_DmButton, {
        rounded: "",
        color: "primary",
        dark: "",
        onClick: _ctx.openConfirmation
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('pickup.pickup')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 2),
    _createVNode(_component_PickupItemConfirmation, {
      modelValue: _ctx.isConfirmationOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isConfirmationOpen = $event)),
      "license-plate-number": _ctx.item.licensePlateNumber,
      car: _ctx.item.car,
      "repair-id": _ctx.item.id,
      onPickup: _ctx.pickup
    }, null, 8, ["modelValue", "license-plate-number", "car", "repair-id", "onPickup"])
  ], 2))
}